@import url('https://use.typekit.net/jnx1kcm.css');

body {
  background-color: #c3baba;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

h1 {
  letter-spacing: .6em;
  user-select: none;
}

h2 {
  color: #EA553B;
  font-family: hightower-text, serif;
  font-style: normal;
  font-weight: 400;
  user-select: none;
} 

h3 {
  font-family: 'Letter Gothic Std', monospace; 
  color: rgb(15,15,15);
}

h4 {
  color: #EA553B;
  font-family: hightower-text, serif;
}

p {
  font-family: 'Letter Gothic Std', monospace;
  font-weight: 700;
  font-style: normal;
}

.my-link {
  color: #EA553B;
}

.my-link:hover {
  color: #ff5c3f;
}

.music, .gigs {
  margin-top: 75px;
}

table {
  margin: auto;
  width: 100%;
}

th {
  width: 200px;
  color: #EA553B;
  font-family: 'Letter Gothic Std', monospace;
  padding: 5px 10px;
  background-color: #c3baba;
}

td {
  border-top: 5px solid #343131;
  padding: 10px;
  background-color: #c3babacc;
}

.accordionToggle {
  background-color: transparent;
  border: none;
}

.rotating-snake {
  width: 40%;
  position: fixed;
}

.snake-left {
  left: -25%;
}

.snake-right {
  right: -25%;
}

.hand-l, .hand-r {
  width: 100%;
}

.hand-l {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.cover {
  margin-top: -15%;
}

.coverphoto {
  width: 100%;
  z-index: -10;
  position: fixed;
  top: 10;
  left: 0;
}

.coverphoto-about {
  margin-top: -60px;
}

.rp-logo {
  width: 200px;
  z-index: -5;
  position: fixed;
  top: 10%;
  left: 3%;
}

.about-text {
  font-family: hightower-text, serif;
  font-weight: 400;
  color: #EA553B;
  user-select: none;
  font-size: 10vw;
  letter-spacing: .5em;
  margin-top: 10vh;
  line-height: 1em;
}

.text-red {
  color: #EA553B;
}

.brand {
  width: 50px;
}

.bg-spots-dark {
  background-image: url('images/bakgrund mörk.png');
  background-repeat: repeat;
}

.bg-spots-light {
  background-image: url('images/bakgrund.jpg');
  background-repeat: repeat;
}

.bg-spots-dark-center {
  background-image: url('images/bakgrund mörk mitt.png');
  background-repeat: repeat-y;
  background-position: center;
}

.bg-fixed {
 background-attachment: fixed;
}

.lots-of-bottom-margin {
  margin-bottom: 100vh;
}

.lots-of-top-margin {
  margin-top: 30vh;
}

.footer-snake {
  width: 100%;
}

.graysnake {
 float: right;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2rem;
}

.content {
  position: relative;
  min-height: 100vh;
  padding-bottom: 2rem;
}

.footer-icon {
  width: 30px;
  margin: 10px;
  fill: #343131;
}

.navbar-icon {
  width: 30px;
  margin: 5px;
  fill: #343131;
}

.follow-icon {
  width: 45px;
  margin: 10px;
  fill: #d14c35;
}

.icon:hover {
  fill: #ff5c3f;
}

.accordion-header {
  color: rgb(15, 15, 15);
  margin-bottom: -5px;
}

.accordion-header:hover {
  color:rgb(0, 0, 0);
}

.border-red {
  border: 1px solid #EA553B;
  border-radius: 2px;
}

.habitats {
  width: 100%;
}

.biography-year {
  font-size: 12em;
  color: #EA553B;
  line-height: 0.7;
  letter-spacing: 0;
}

.pink-bg-fluff {
  background-color: #d3bcb67e;
}

.biography-btn {
  background-color: #d3bcb67e;
  box-shadow: #d3bcb67e 0px 0px 10px 10px;
  border: none;
  outline: none;
  height: 40px;
}

.biography-btn:focus {
  outline: none;
  border: none;
}

.biography-btn:hover {
  color: #d14c35;
  font-weight: 900;
}

.active-year {
  color: #d14c35;
}

.min-h {
  min-height: 400px;
}

.members img {
  width: 100%;
} 

.members img:hover {
  filter: invert(1);
}

.max-100vw {
  max-width: 100vw;
}

.members {
  width: 103%;
  margin-left: -15px;
}

.members-text {
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 1.5em;
    width: 100%;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
  .members-text {
    margin-top: 1em;
    letter-spacing: .3em;
  }
  .rotating-snake {
    width: 90%;
    position: fixed;
    left: 10px;
    top: 100px;
  }

  .snake-left {
    margin: auto;
  }

  .snake-right {
    visibility: hidden;
  }
  .music, .gigs {
    margin-top: 60px;
  }

  table {
    max-width: 100vw;
  }

  th {
    padding: 1px;
    font-size: .5em;
  }
  
  td {
    padding: 1px;
    font-size: .5em;
  }

  .min-h {
    min-height: 100px;
  }

  .navbar-icons a {
    visibility: collapse;
  }
  
  .coverphoto {
    width: 110%;
    z-index: -10;
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 58px;
  }
  
  .footer-snake {
    width: 90%;
  }

  .follow-us {
    margin-top: 100vh;
  }

  .biography h1 {
    font-size: 1.3em;
  }

  .biography h1 {
    color: #EA553B;
    font-weight: 700;
  }

  .biography-year {
    font-size: 5em;
  }

  .hide-xs {
    visibility: collapse;
    height: 0;
  }

  .lots-of-bottom-margin {
    margin-bottom: 100vh;
  }

  .contact {
    margin-top: 100px;
  }
  
  .members {
    width: 106%;
    margin-left: -15px;
  }
}


/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
  .rp-logo {
    width: 40%;
    z-index: -5;
    position: absolute;
    top: 45%;
    left: 30%;
  }
}

@media (max-width: 1550px) { 
 /*  .coverphoto-about {
    height: 100vh;
    width: auto;
    margin-left: 0;
  }

  .translate {
    animation: translate 15s alternate infinite;
    animation-delay: 1s;
  }
  
  @keyframes translate {
    0% { margin-left: 0;}
    100% {margin-left: -10%;}
  }  */
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1199.98px) { 

}


