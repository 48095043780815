/*! Bootstrap 4 custom build by Themestr.app */

$red:#ea553b;
$gray-100: #c3baba;
$gray-200:#D3BCB6;
$gray-800:#222020;
$mark-bg:#ea553b;
$font-size-base:1.2rem;

/*! Import Bootstrap 4 variables */
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import url('https://use.typekit.net/jnx1kcm.css');

$primary:$red;
$navbar-light-hover-color:rgba($red, .7);
$navbar-light-active-color:rgba($red, .9);
$body-bg:$gray-100;
$h1-font-size:$font-size-base * 5;
$enable-responsive-font-sizes:true;
$card-bg:rgba($black, 0);
$card-cap-bg:rgba($black, 0);
$card-border-color:rgba($black, 0.2);

$font-family-sans-serif: 'Montserrat', BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif:      hightower-text, 'Rozha One', Georgia, "Times New Roman", Times, serif;
$font-family-monospace:  'Letter Gothic Std', Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-serif;
@import "bootstrap";
